import React from 'react';
import { st, classes } from './TextSubscriptionInfo.st.css';

export const TextSubscriptionInfo: React.FC<{ dataHook?: string; children: React.ReactNode }> = ({
  children,
  dataHook,
}) => (
  <span className={st(classes.root, {})} data-hook={dataHook}>
    {children}
  </span>
);

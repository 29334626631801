import React from 'react';
import { st, classes } from './TextSubscriptionName.st.css';

interface TextSubscriptionNameProps {
  children: React.ReactNode;
}

export const TextSubscriptionName: React.FC<TextSubscriptionNameProps> = ({ children }) => (
  <span className={st(classes.root, {})}>{children}</span>
);

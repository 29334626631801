import React from 'react';
import { isAfter } from 'date-fns';
import classNames from 'classnames';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { TPAComponentsConsumer, Spinner } from 'wix-ui-tpa';
import { Tooltip } from '@wix/design-system';
import { connect } from '../../../../common/components/runtime-context';
import { ArrowUpIcon } from '../ArrowUpIcon';
import { ArrowDownIcon } from '../ArrowDownIcon';
import { TextSubscriptionName } from '../TextSubscriptionName';
import {
  getDetails,
  getRegionalSettings,
  isDetailsLoading as isDetailsLoadingSelector,
  isDetailsOpen as isDetailsOpenSelector,
} from '../../selectors';
import { TextSubscriptionInfo } from '../TextSubscriptionInfo';
import { classes, st } from './SubscriptionsListItemBass.st.css';
import { BOOKINGS_APP_DEF_ID, STORES_APP_DEF_ID } from '../../constants';
import { RootState } from '../../state';
import { CollectionMethod, Subscription, SubscriptionStatus } from '@wix/ambassador-billing-v1-subscription/types';
import { BenefitType } from '@wix/ambassador-pricing-plan-benefits-server/types';
import { isPendingCancellationSubscription } from '../../domainUtils';
import { formatDate } from '../../../../utils/displayUtils';
import SubscriptionPaymentsDetails from './SubscriptionPaymentsDetails';
import CallToActions from './CallToActions';
import { IActionCreators } from '../../../../types/internal-types';
import { getStatusTextTranslationKey, StatusBadgeBass } from '../StatusBadgeBass';
import CPDSectionHelper from './CPDSectionHelper';
import ExternalSubscriptionSectionHelper from './ExternalSubscriptionSectionHelper';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

interface Props {
  subscription: Subscription;
}

const SubscriptionsListItemBass = ({
  subscription,
  isDetailsOpen,
  isDetailsLoading,
  actionCreators,
  details,
  regionalSettings,
}: Props & RuntimeProps) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const endDate = subscription.endDate ?? undefined;
  const hasEndDate = Boolean(endDate);
  const validFrom = subscription.startDate ?? undefined;
  const isPaymentDateChanged = subscription.billingStatus?.updateBillingDateData;
  let trialEndDate: Date | undefined;
  let inTrial = false;

  if (subscription.billingSettings?.freeTrialDuration && subscription.startDate) {
    trialEndDate = subscription.billingStatus?.freeTrialData?.endDate ?? undefined;
    if (trialEndDate) {
      inTrial = subscription.status === SubscriptionStatus.ACTIVE && isAfter(trialEndDate, new Date());
    }
  }

  const isSubscriptionExternal = subscription.billingSettings?.collectionMethod === CollectionMethod.EXTERNAL;

  const getSubscriptionHeader = () => {
    const lines: string[] = [];
    if (subscription.description && subscription.origin?.appId === STORES_APP_DEF_ID) {
      lines.push(subscription.description);
    }
    if (subscription.name) {
      lines.push(subscription.name);
    }
    return lines;
  };

  const getEndDate = (): string | null => {
    const cancellationDate = subscription.cancellationInfo?.cancellationDate ?? endDate; // endDate is sent as fallback due to backend bug in migrating of SAPI subscriptions.
    switch (subscription.status) {
      case SubscriptionStatus.PENDING:
        return t('app.title.starts', {
          date: formatDate(validFrom, regionalSettings),
          interpolation: { escapeValue: false },
        });
      case SubscriptionStatus.ACTIVE:
        if (isPendingCancellationSubscription(subscription)) {
          return t('app.title.expires', {
            date: formatDate(cancellationDate, regionalSettings),
            interpolation: { escapeValue: false },
          });
        }
        return hasEndDate
          ? t('app.title.expires', {
              date: formatDate(endDate, regionalSettings),
              interpolation: { escapeValue: false },
            })
          : t('app.title.valid-until-canceled');
      case SubscriptionStatus.PAUSED:
        return null;
      case SubscriptionStatus.ENDED:
        return t('app.title.expired', {
          date: formatDate(endDate, regionalSettings),
          interpolation: { escapeValue: false },
        });
      case SubscriptionStatus.CANCELED:
        return t('app.title.canceled', {
          date: formatDate(cancellationDate, regionalSettings),
          interpolation: { escapeValue: false },
        });
      default:
        return null;
    }
  };

  const getStartDate = (): string => {
    if (subscription.status === SubscriptionStatus.PENDING) {
      return hasEndDate
        ? t('app.details.expires', { date: formatDate(endDate, regionalSettings) })
        : t('app.details.valid-until-canceled');
    }
    return t('app.details.start-date', {
      date: formatDate(validFrom, regionalSettings),
      interpolation: { escapeValue: false },
    });
  };

  const getTrialEnds = (): string | null =>
    inTrial
      ? t('app.details.trial-ends', {
          date: formatDate(subscription.billingStatus?.freeTrialData?.endDate!, regionalSettings),
          interpolation: { escapeValue: false },
        })
      : null;

  const getVerticalsData = () => {
    const lines: string[] = [];
    if (details?.benefitBalanceItems?.length) {
      const [bookingsItem] = details.benefitBalanceItems.filter(
        (item) => item.benefit?.appDefId === BOOKINGS_APP_DEF_ID,
      );

      if (bookingsItem?.benefit?.benefitType === BenefitType.LIMITED) {
        const { remainingCredits, totalCredits } = bookingsItem?.benefit;
        lines.push(t('app.details.sessions-remaining-without-total', { remainingCredits, totalCredits }));
      }
    }
    if (details?.storesSubscription) {
      const { lineItems, shippingInfo } = details.storesSubscription;
      lines.push(t('app.details.quantity', { quantity: lineItems?.[0].quantity }));
      if (shippingInfo?.shipmentDetails?.address) {
        const { address } = shippingInfo.shipmentDetails;
        type AddressType = typeof shippingInfo.shipmentDetails.address;
        const addressLines = ['addressLine1', 'addressLine2', 'city', 'zipCode', 'country']
          .map((field) => address[field as keyof AddressType])
          .filter(Boolean);

        if (addressLines.length) {
          lines.push(t('app.details.shipping-to'));
          addressLines.forEach((line) => lines.push(line as string));
        }
      }
    }
    return lines;
  };

  const SubscriptionHeader = getSubscriptionHeader();
  const EndDate = getEndDate();
  const StartDate = getStartDate();
  const TrialEnds = getTrialEnds();
  const VerticalsData = getVerticalsData();

  return (
    <TPAComponentsConsumer>
      {({ mobile }) => (
        <div className={st(classes.root, { desktop: !isMobile, mobile: isMobile })} data-hook="subscription-list-item">
          <div
            id={`subscription-title-${subscription.id}`}
            className={classes.titleContainer}
            data-hook="subscription-list-item-title"
            onClick={() =>
              isDetailsOpen
                ? actionCreators.closeDetails(subscription.id)
                : actionCreators.openDetails(subscription.id!)
            }
          >
            <div className={classes.lft}>
              <div className={classNames(classes.title, classes.columnA)}>
                {SubscriptionHeader.map((line, i) => (
                  <div key={i}>
                    <TextSubscriptionName>{line}</TextSubscriptionName>
                  </div>
                ))}
              </div>
              {EndDate && (
                <div className={classes.columnB} data-hook="subscription-date-info">
                  <TextSubscriptionName>{EndDate}</TextSubscriptionName>
                </div>
              )}
              {mobile && (
                <div className={classes.statusBadgeContainer}>
                  <StatusBadgeBass subscription={subscription} />
                </div>
              )}
            </div>
            <div className={classes.rgt}>
              {!mobile && (
                <div className={classes.tooltipWrapper}>
                  <Tooltip content={t(getStatusTextTranslationKey(subscription))} interactive placement="top">
                    <div className={classes.statusBadgeContainer}>
                      <StatusBadgeBass subscription={subscription} />
                    </div>
                  </Tooltip>
                </div>
              )}
              <div className={classes.iconContainer}>
                <button
                  style={{ fontSize: 0 }}
                  aria-expanded={isDetailsOpen ? 'true' : 'false'}
                  aria-label="more details"
                  aria-describedby={`subscription-title-${subscription.id}`}
                >
                  {isDetailsOpen ? (
                    <ArrowUpIcon className={classes.arrow} />
                  ) : (
                    <ArrowDownIcon className={classes.arrow} />
                  )}
                </button>
              </div>
            </div>
          </div>

          {isDetailsOpen && (
            <div className={classes.details} data-hook="subscription-list-item-details">
              <div className={classes.contentContainer}>
                {isDetailsLoading && !details && (
                  <div className={classes.loader} data-hook="subscription-list-item-details-loader">
                    <Spinner />
                  </div>
                )}
                {details && (
                  <>
                    <div className={classes.columnA}>
                      <SubscriptionPaymentsDetails subscription={subscription} />
                    </div>
                    <div className={classes.columnB}>
                      <div>
                        <TextSubscriptionInfo>{StartDate}</TextSubscriptionInfo>
                      </div>
                      {TrialEnds && (
                        <div>
                          <TextSubscriptionInfo>{TrialEnds}</TextSubscriptionInfo>
                        </div>
                      )}
                      {VerticalsData.map((line, i) => (
                        <div key={i}>
                          <TextSubscriptionInfo>{line}</TextSubscriptionInfo>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
              {isPaymentDateChanged && (
                <div className={classes.sectionHelper}>
                  <CPDSectionHelper subscription={subscription} />
                </div>
              )}
              {isSubscriptionExternal && (
                <div className={classes.sectionHelper}>
                  <ExternalSubscriptionSectionHelper subscription={subscription} />
                </div>
              )}
              {details && <CallToActions subscription={subscription} />}
            </div>
          )}
        </div>
      )}
    </TPAComponentsConsumer>
  );
};

const mapRuntimeToProps = (state: RootState, { subscription }: Props, actionCreators: IActionCreators) => {
  const subscriptionId: string = subscription.id!;
  return {
    isDetailsOpen: isDetailsOpenSelector(state, subscriptionId),
    isDetailsLoading: isDetailsLoadingSelector(state, subscriptionId),
    actionCreators,
    details: getDetails(state, subscriptionId),
    regionalSettings: getRegionalSettings(state),
  };
};

export default connect(mapRuntimeToProps)(SubscriptionsListItemBass);

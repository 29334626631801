import React from 'react';
import { Button, ButtonPriority, InputDialog, ThreeDotsLoader } from 'wix-ui-tpa';
import { useBi, useEnvironment, useErrorMonitor, useFedopsLogger, useTranslation } from '@wix/yoshi-flow-editor';
import { connect } from '../../../../common/components/runtime-context';
import { getDetails, getLanguage, getUpmModalSubscription, isUpmModalOpen } from '../../selectors';
import { RootState } from '../../state';
import { PaymentsWidget, PaymentsWidgetAPI } from '@wix/cashier-payments-widget/lazy';
import { IActionCreators } from '../../../../types/internal-types';
import { Subscription } from '@wix/ambassador-billing-v1-subscription/types';
import { classes, st } from './UpmModal.st.css';
import classNames from 'classnames';
import { Interactions } from '../../../../types/interactions';
import { UpmFlowStatus } from '../../constants';
import { aClickCancelUpmEvent, aClickSubmitUpmEvent } from '@wix/bi-logger-my-subscriptions/v2';
import { getPaymentMethodDisplayName } from '../../../../utils/displayUtils';
import { PageType } from '@wix/cashier-common/dist/src/enums/PageType';
import { fetchPaymentMethodWithFallback } from '../../../../utils/UpmAndPayNowUtils';
import { PaymentsWidgetCallbacks } from '@wix/cashier-payments-widget/dist/src/types/PaymentsWidgetCallbacks';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;
interface Props {
  subscription: Subscription;
}
export const UpmModal = ({
  isOpen,
  subscription,
  actionCreators,
  language,
  IdentityParams,
  upmFlowStatus,
  subscriptionExtraDetails,
}: RuntimeProps & Props) => {
  const { isMobile } = useEnvironment();
  const biLogger = useBi();

  const [shouldDisableSubmitBtn, setShouldDisableSubmitBtn] = React.useState(false);
  const [isWidgetFullyMounted, setIsWidgetFullyMounted] = React.useState(false);
  const paymentsWidgetApi = React.useRef<PaymentsWidgetAPI | undefined>();
  const { t } = useTranslation();
  const { msid, instance, siteOwnerId, sessionId, visitorId } = IdentityParams;
  const fedopsLogger = useFedopsLogger();
  const errorMonitor = useErrorMonitor();
  const endInteraction = () => {
    fedopsLogger.interactionEnded(Interactions.SubscriptionUpm);
    actionCreators.setUpmFlowStatus(UpmFlowStatus.INIT);
  };
  const upmModalSubmit = async () => {
    biLogger.report(aClickSubmitUpmEvent({ subscriptionId: subscription.id! }));
    fedopsLogger.interactionStarted(Interactions.SubscriptionUpm);
    if (upmFlowStatus === UpmFlowStatus.INIT) {
      actionCreators.setUpmFlowStatus(UpmFlowStatus.PENDING);
      const savedPaymentMethodResponse = await fetchPaymentMethodWithFallback(
        paymentsWidgetApi.current,
        errorMonitor,
        endInteraction,
      );
      if (savedPaymentMethodResponse) {
        if (savedPaymentMethodResponse.paymentAgreementId) {
          const paymentMethodId = savedPaymentMethodResponse?.paymentAgreementId || '';
          actionCreators.submitUpm({
            subscriptionId: subscription.id!,
            paymentMethodId,
          });
        } else {
          errorMonitor.captureMessage(
            `payments api didn't return paymentMethodId in the response in upm flow for subscription id ${subscription.id}`,
          );
          endInteraction();
        }
      }
    } else {
      fedopsLogger.interactionEnded(Interactions.SubscriptionUpm);
    }
  };

  const onClickCloseModal = (referral: string) => {
    if (upmFlowStatus !== UpmFlowStatus.PENDING) {
      biLogger.report(aClickCancelUpmEvent({ referral, subscriptionId: subscription.id! }));
      actionCreators.closeUpmModal();
    }
  };

  const getModalSubtitle = () => {
    const shouldShowModalSubtitle =
      subscriptionExtraDetails.paymentMethodDetails?.savedCreditCardDetails?.network &&
      subscriptionExtraDetails.paymentMethodDetails?.savedCreditCardDetails?.lastFourDigits &&
      subscription.name;
    if (shouldShowModalSubtitle) {
      return t('app.checkout-form.upm.subtitle-info', {
        subscriptionName: subscription.name,
        paymentMethod: getPaymentMethodDisplayName(
          subscriptionExtraDetails.paymentMethodDetails?.savedCreditCardDetails?.network,
          t,
        ),
        cardNumber: subscriptionExtraDetails.paymentMethodDetails?.savedCreditCardDetails?.lastFourDigits,
      });
    }
    return undefined;
  };
  const onSelectPaymentMethod: PaymentsWidgetCallbacks['paymentMethodChanged'] = (paymentMethodId, details) => {
    if (
      details?.paymentAgreementId &&
      details?.paymentAgreementId === subscription.billingSettings!.paymentMethod?.id
    ) {
      setShouldDisableSubmitBtn(true);
    } else {
      setShouldDisableSubmitBtn(false);
    }
  };
  return (
    <div className={classNames(st(classes.root, { desktop: !isMobile }))}>
      <InputDialog
        title={t('app.checkout-form.upm.title')}
        subtitle={getModalSubtitle()}
        data-hook="upm-modal"
        isOpen={isOpen}
        onClose={() => {
          onClickCloseModal('exit_window');
        }}
        fullscreen={isMobile}
        className="modal-content"
        customFooter={
          <div className={classes.modalFooter}>
            <Button
              onClick={upmModalSubmit}
              fullWidth={isMobile}
              data-hook="upm-submit-button"
              upgrade
              disabled={shouldDisableSubmitBtn || !isWidgetFullyMounted}
            >
              {upmFlowStatus === UpmFlowStatus.PENDING ? (
                <ThreeDotsLoader className={classes.threeDotsLoader} />
              ) : (
                t('app.checkout-form.pay-now-upm.submit.button')
              )}
            </Button>
            {!isMobile && (
              <Button
                onClick={() => onClickCloseModal('cancel_button')}
                data-hook="upm-cancel-button"
                priority={ButtonPriority.basicSecondary}
                disabled={upmFlowStatus === UpmFlowStatus.PENDING}
                upgrade
              >
                {t('app.checkout-form.pay-now-upm.cancel.button')}
              </Button>
            )}
          </div>
        }
      >
        <div
          className={classNames(classes.paymentsWidgetContainer, {
            [classes.pendingResponse]: upmFlowStatus === UpmFlowStatus.PENDING,
          })}
          data-hook="payments-widget-container"
        >
          <PaymentsWidget
            configuration={{
              locale: language,
              appId: IdentityParams.appDefinitionId,
              appInstanceId: IdentityParams.appInstanceId,
              appInstance: instance,
              amount: '0',
              currency: subscription.billingSettings!.currency!,
              msid: msid || '',
              siteOwnerId,
              visitorId,
              viewMode: 'Site',
              isSignedInUser: true,
              appSessionId: sessionId,
            }}
            autoSelectedPaymentAgreementId={subscription.billingSettings!.paymentMethod?.id}
            externalSubmitButton // ability to show our own button
            onCrash={() => {
              setShouldDisableSubmitBtn(true);
              errorMonitor.captureMessage(`Payments Widget Crashed On UPM ${subscription.id}`);
            }} // Payments will show error message,
            onApiInit={(api) => {
              paymentsWidgetApi.current = api;
            }}
            onFullLoad={() => {
              setIsWidgetFullyMounted(true);
            }}
            paymentMethodChanged={onSelectPaymentMethod}
            isSaveCCEnabled={true}
            allowRecurringPaymentOnly
            allowSaveableMethodsOnly
            pageType={PageType.UPMComponent}
          />
          <div className={classes.afterWidgetPadding} />
        </div>
      </InputDialog>
    </div>
  );
};

const mapRuntimeToProps = (state: RootState, _: {}, actionCreators: IActionCreators) => {
  return {
    isOpen: isUpmModalOpen(state),
    subscription: getUpmModalSubscription(state),
    actionCreators,
    language: getLanguage(state),
    IdentityParams: state.IdentityParams,
    upmFlowStatus: state.upmModal.upmFlowStatus,
    subscriptionExtraDetails: getDetails(state, state.upmModal.subscriptionId!),
  };
};

export default connect(mapRuntimeToProps)(UpmModal);

import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Divider } from './Divider';
import SubscriptionsListItemBass from './SubscriptionListItemBass/SubscriptionsListItemBass';
import { st, classes } from './SubscriptionsList.st.css';
import { Subscription } from '@wix/ambassador-billing-v1-subscription/types';

interface SubscriptionsListProps {
  subscriptions: Subscription[];
}

const SubscriptionsListBass = ({ subscriptions }: SubscriptionsListProps) => {
  const { isMobile } = useEnvironment();
  return (
    <>
      {!isMobile && <Divider />}
      <ul className={st(classes.root, isMobile ? { mobile: isMobile } : undefined)} data-hook="subscriptions-list">
        {subscriptions.map((subscription) => (
          <li key={subscription.id}>
            <SubscriptionsListItemBass subscription={subscription} />
            <Divider />
          </li>
        ))}
      </ul>
    </>
  );
};

export default SubscriptionsListBass;

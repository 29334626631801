import React from 'react';
import { RootState } from '../../../components/MySubscriptions/state';

interface IContextParams {
  state: RootState;
  actions: Actions;
  host: Host;
}

export type Actions = any;
export type Host = any;

export default React.createContext<IContextParams>({
  state: undefined as unknown as RootState,
  actions: undefined,
  host: undefined,
});

import { PaymentsWidgetAPI } from '@wix/cashier-payments-widget';
import { ErrorMonitor } from '@wix/yoshi-flow-editor/error-monitor';

const isValidationError = (e: any) => e?.isValid === false;

export async function fetchPaymentMethodWithFallback(
  paymentsWidgetApi: PaymentsWidgetAPI | undefined,
  errorMonitor: ErrorMonitor,
  endInteraction: () => void,
): Promise<{ paymentAgreementId: string } | undefined> {
  return paymentsWidgetApi?.getSavedPaymentMethod().catch((e) => {
    if (!isValidationError(e)) {
      errorMonitor.addBreadcrumb({
        message: 'error in payments side when trying to create/get payment method on payments cashier widget',
      });
      errorMonitor.captureException(e);
    }
    endInteraction();
    return undefined;
  });
}

import React, { Component } from 'react';
import getDisplayName from 'react-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';
import RuntimeContext, { Actions, Host } from './runtime-context';
import { RootState } from '../../../components/MySubscriptions/state';

type MapRuntimeToProps<P> = (state: RootState, props: P, actions: Actions, host: Host) => any;

function connect<P>(mapRuntimeToProps: MapRuntimeToProps<P>) {
  return function <C extends P>(WrappedComponent: any) {
    class ConnectedComponent extends Component<P> {
      static contextType = RuntimeContext;
      static displayName = `connect(${getDisplayName(WrappedComponent)})`;

      render() {
        const { state, actions, host } = this.context as any;
        const props = mapRuntimeToProps(state, this.props, actions, host);
        return <WrappedComponent {...(this.props as C)} {...props} />;
      }
    }

    hoistNonReactStatics(ConnectedComponent, WrappedComponent);

    return ConnectedComponent;
  };
}
export default connect;

import React from 'react';

type ArrowDownIconProps = {
  className?: string;
};

export const ArrowDownIcon = (props: ArrowDownIconProps) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      d="M11.999 9L6 14.241 6.657 15 11.999 10.334 17.343 15 18 14.241z"
      transform="rotate(180 12 12)"
    />
  </svg>
);

import { StyleParamType, wixColorParam, wixFontParam, createStylesParams } from '@wix/tpa-settings';

type IStylesParams = {
  pageTitleFont: StyleParamType.Font;
  pageTitleColor: StyleParamType.Color;
  pageSubtitleFont: StyleParamType.Font;
  pageSubtitleColor: StyleParamType.Color;
  subscriptionNameFont: StyleParamType.Font;
  subscriptionNameColor: StyleParamType.Color;
  subscriptionInfoFont: StyleParamType.Font;
  subscriptionInfoColor: StyleParamType.Color;
  noSubscriptionMessageFont: StyleParamType.Font;
  noSubscriptionTitleFont: StyleParamType.Font;
  noSubscriptionMessageColor: StyleParamType.Color;
  noSubscriptionTitleColor: StyleParamType.Color;
  linkFont: StyleParamType.Font;
  linkColor: StyleParamType.Color;
  detailsLinkFont: StyleParamType.Font;
  detailsLinkColor: StyleParamType.Color;
  backgroundColor: StyleParamType.Color;
  backgroundBorderWidth: StyleParamType.Number;
  backgroundBorderColor: StyleParamType.Color;
  dividerColor: StyleParamType.Color;
  dividerWidth: StyleParamType.Number;
  textFont: StyleParamType.Font;
  textColor: StyleParamType.Color;
};

export default createStylesParams<IStylesParams>({
  pageTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Heading-M', {
      size: 28,
    }),
  },
  pageTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  pageSubtitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  pageSubtitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  subscriptionNameFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  subscriptionNameColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  subscriptionInfoFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  subscriptionInfoColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-4'),
  },
  noSubscriptionMessageFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  noSubscriptionTitleFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 24,
    }),
  },
  noSubscriptionMessageColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  noSubscriptionTitleColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  linkFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  linkColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  detailsLinkFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  detailsLinkColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  backgroundBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  backgroundBorderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  dividerColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.3),
  },
  dividerWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  textFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 20,
    }),
  },
  textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
});

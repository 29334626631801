import { Subscription } from '@wix/ambassador-billing-v1-subscription/types';
import React from 'react';
import { TextButton } from 'wix-ui-tpa';
import { connect } from '../../../../common/components/runtime-context';
import { IActionCreators } from '../../../../types/internal-types';
import { RootState } from '../../state';
import { classes } from './SubscriptionsListItemBass.st.css';
import { useSettings } from '@wix/tpa-settings/react';
import settingsParams from '../../settingsParams';
import { PAID_PLANS_APP_DEF_ID, STORES_APP_DEF_ID } from '../../constants';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

interface Props {
  subscription: Subscription;
}
const NavigateToVerticalCta = ({ subscription, actionCreators }: Props & RuntimeProps) => {
  const isPPSubscription = subscription.origin?.appId === PAID_PLANS_APP_DEF_ID;
  const isStoresSubscription = subscription.origin?.appId === STORES_APP_DEF_ID;
  const navigateToVertical = () => {
    if (isPPSubscription) {
      return actionCreators.navigateToPricingPlans();
    }
    if (isStoresSubscription) {
      return actionCreators.navigateToStores();
    }
  };
  const settings = useSettings();
  const buyNewPlanText = settings.get(settingsParams.buyNewPlanText);

  return (
    <TextButton data-hook="buy-new-plan-button" className={classes.cta} onClick={navigateToVertical}>
      {buyNewPlanText}
    </TextButton>
  );
};

const mapRuntimeToProps = (state: RootState, { subscription }: Props, actionCreators: IActionCreators) => {
  return {
    actionCreators,
  };
};

export default connect(mapRuntimeToProps)(NavigateToVerticalCta);

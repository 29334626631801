import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'wix-ui-tpa';
import { connect } from '../../../../common/components/runtime-context';
import { IActionCreators } from '../../../../types/internal-types';
import { RootState } from '../../state';
import { classes } from './InfiniteScrollProvider.st.css';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;
// TODO integration with infiniteScroll is not tested

interface InfiniteScrollProviderProps extends RuntimeProps {
  children: React.ReactNode;
}
const InfiniteScrollProvider: React.FC<InfiniteScrollProviderProps> = ({
  children,
  subscriptionsList,
  fetchNextPage,
  nextCursor,
}) => {
  return (
    <InfiniteScroll
      dataLength={subscriptionsList.length}
      next={() => fetchNextPage()}
      hasMore={Boolean(nextCursor)}
      className={classes.infiniteScroll}
      loader={
        <div className={classes.loaderContainer}>
          <Spinner diameter={30} />
        </div>
      }
    >
      {children}
    </InfiniteScroll>
  );
};

const mapRuntimeToProps = (state: RootState, _: {}, actionCreators: IActionCreators) => {
  return {
    subscriptionsList: state.subscriptions.entities,
    fetchNextPage: actionCreators.fetchAllSubscriptions,
    nextCursor: state.subscriptions.cursors?.next,
  };
};

export default connect(mapRuntimeToProps)(InfiniteScrollProvider);

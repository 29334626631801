import React from 'react';
import { connect } from '../../../../common/components/runtime-context';
import { RootState } from '../../state';
import { IActionCreators } from '../../../../types/internal-types';
import { Toast, ToastSkin } from 'wix-ui-tpa';
import { classes, st } from './Toast.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import classNames from 'classnames';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

const ToastComponent = ({ isShown, message, actionCreators, toastSkin }: RuntimeProps) => {
  const { isMobile } = useEnvironment();
  const getSkin = () => {
    const skinMap = {
      success: ToastSkin.success,
      error: ToastSkin.error,
      status: ToastSkin.status,
    };
    return skinMap[toastSkin] ?? ToastSkin.success;
  };
  return isShown ? (
    <div className={classNames(st(classes.root, { mobile: isMobile, desktop: !isMobile }))}>
      <div className={classes.container}>
        <Toast
          data-hook="toast"
          skin={getSkin()}
          shouldShowCloseButton
          shouldAnimate
          onClose={() => actionCreators.closeToast()}
          isShown={isShown}
        >
          {message}
        </Toast>
      </div>
    </div>
  ) : null;
};

const mapRuntimeToProps = (state: RootState, _: {}, actionCreators: IActionCreators) => {
  return {
    isShown: state.toast.isShown,
    message: state.toast.message,
    toastSkin: state.toast.skin,
    actionCreators,
  };
};

export default connect(mapRuntimeToProps)(ToastComponent);

import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { isAfter } from 'date-fns';
import { Badge } from '@wix/design-system';
import { st, classes } from './StatusBadgeBass.st.css';
import { Subscription, SubscriptionStatus } from '@wix/ambassador-billing-v1-subscription/types';
import { isPendingCancellationSubscription } from '../domainUtils';

const activeStatuses = [SubscriptionStatus.ACTIVE];

const statusTranslations: Record<SubscriptionStatus, string> = {
  [SubscriptionStatus.UNKNOWN]: 'app.subscription-status.undefined',
  [SubscriptionStatus.DRAFT]: 'app.subscription-status.draft',
  [SubscriptionStatus.PENDING]: 'app.subscription-status.pending',
  [SubscriptionStatus.ACTIVE]: 'app.subscription-status.active',
  [SubscriptionStatus.ENDED]: 'app.subscription-status.expired',
  [SubscriptionStatus.CANCELED]: 'app.subscription-status.canceled',
  [SubscriptionStatus.PAUSED]: 'app.subscription-status.suspended',
};

const isInFreeTrial = (subscription: Subscription) => {
  if (subscription.status === SubscriptionStatus.ACTIVE && subscription.billingSettings?.freeTrialDuration) {
    const freeTrialEnd = subscription.billingStatus?.freeTrialData?.endDate;
    const today = new Date();
    if (freeTrialEnd && isAfter(freeTrialEnd, today)) {
      return true;
    }
  }

  return false;
};

export const getStatusTextTranslationKey = (subscription: Subscription) => {
  if (
    [SubscriptionStatus.ACTIVE, SubscriptionStatus.PENDING].indexOf(subscription.status ?? SubscriptionStatus.UNKNOWN) >
      -1 &&
    isPendingCancellationSubscription(subscription)
  ) {
    return 'app.subscription-status.pending-cancellation';
  } else if (isInFreeTrial(subscription)) {
    return 'app.subscription-status.free-trial';
  }
  return statusTranslations[subscription.status! as SubscriptionStatus];
};

type Props = {
  subscription: Subscription;
};

export const StatusBadgeBass = ({ subscription }: Props) => {
  const { t } = useTranslation();
  const inFreeTrial = isInFreeTrial(subscription);
  const active = inFreeTrial || activeStatuses.indexOf(subscription.status!) > -1;

  return (
    <Badge
      className={st(classes.root)}
      uppercase={false}
      skin={active ? 'neutralSuccess' : 'neutralLight'}
      dataHook="status-badge"
    >
      {t(getStatusTextTranslationKey(subscription))}
    </Badge>
  );
};

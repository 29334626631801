import React from 'react';

type ArrowUpIconProps = {
  className?: string;
};

export const ArrowUpIcon = (props: ArrowUpIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path fillRule="evenodd" d="M11.999 9L6 14.241 6.657 15 11.999 10.334 17.343 15 18 14.241z" />
  </svg>
);

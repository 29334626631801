import { Subscription } from '@wix/ambassador-billing-v1-subscription/types';
import React from 'react';
import { SectionNotification, Text } from 'wix-ui-tpa';
import { connect } from '../../../../common/components/runtime-context';
import { IActionCreators } from '../../../../types/internal-types';
import { RootState } from '../../state';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { getRegionalSettings } from '../../selectors';

type RuntimeProps = ReturnType<typeof mapRuntimeToProps>;

interface Props {
  subscription: Subscription;
}
const ExternalSubscriptionSectionHelper = ({ subscription, actionCreators, regionalSetting }: Props & RuntimeProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <SectionNotification type="wired" data-hook="external-subscription-section-helper">
        <SectionNotification.Text>
          <Text>{t('app.manage_subscription.section_helper.ios')}</Text>
        </SectionNotification.Text>
      </SectionNotification>
    </div>
  );
};

const mapRuntimeToProps = (state: RootState, { subscription }: Props, actionCreators: IActionCreators) => {
  return {
    actionCreators,
    regionalSetting: getRegionalSettings(state),
  };
};

export default connect(mapRuntimeToProps)(ExternalSubscriptionSectionHelper);
